.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #8364e8, #7753e5);
  /* box-shadow: 0 1em 2em rgb(0, 0, 0, 0.3); */
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.navigation img {
  width: 10em;
  border-radius: 100%;
  margin: 1em 0;
}

.navigation ul {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.navigation ul li a {
  font-size: 1em;
  color: #fff;
  text-decoration: none;
}

.title h1 {
  margin-top: 1em;
  font-size: 1.2em;
  font-family: "Anton", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 0.3em 0.3em;
  border: 0.2em solid #fff;
  border-radius: 0.3em;
}
