body {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1em;
  color: #fff;
  background: linear-gradient(to right, #8364e8, #7753e5);
}

/* Responsive design */

@media screen and (min-width: 1000px) and (max-width: 1450px) {
  .navigation {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .title {
    width: 45%;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }

  .title h1 {
    margin-top: 0;
    font-size: 1.3em;
  }

  .navigation ul {
    width: 35%;
  }

  .navigation ul li a {
    font-size: 1em;
  }

  .navigation ul li a:hover {
    text-decoration: underline;
  }

  .home-page article {
    width: 60%;
    margin-top: 4em;
    font-size: 1.3em;
  }

  .home-page blockquote {
    font-size: 1.1em;
  }

  .home-page p {
    font-size: 1.1em;
  }

  .about-page article {
    width: 60%;
    font-size: 1.2em;
  }

  .skills-page section {
    width: 60%;
  }

  .skills-page section img {
    width: 4.5em;
    cursor: pointer;
  }

  .skills-page p {
    font-size: 1.2em;
  }

  .model {
    width: 60%;
  }

  .projects-page section {
    width: 60%;
  }

  .projects-page article {
    font-size: 1.2em;
  }

  .projects-page section p a:hover {
    color: #39d66b;
  }

  .contact-page p {
    margin-top: 4em;
    text-align: center;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1451px) {
  .navigation {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .title {
    width: 35%;
    flex-direction: row-reverse;
    justify-content: space-evenly;
  }

  .title h1 {
    margin-top: 0;
    font-size: 1.5em;
  }

  .navigation ul {
    width: 35%;
  }

  .navigation ul li a {
    font-size: 1.2em;
  }

  .navigation ul li a:hover {
    text-decoration: underline;
  }

  .home-page article {
    width: 60%;
    margin-top: 5em;
    font-size: 1.5em;
  }

  .home-page blockquote {
    font-size: 1.3em;
  }

  .home-page p {
    font-size: 1.3em;
  }

  .about-page article {
    width: 60%;
    font-size: 1.4em;
    letter-spacing: 0.04em;
  }

  .skills-page section {
    width: 60%;
  }

  .skills-page section img {
    width: 6em;
    cursor: pointer;
  }

  .skills-page p {
    font-size: 1.5em;
  }

  .model {
    width: 40%;
  }

  .projects-page section {
    width: 60%;
  }

  .projects-page article {
    font-size: 1.4em;
  }

  .projects-page h3 {
    font-size: 2em;
  }

  .projects-page section p a:hover {
    color: #39d66b;
  }

  .contact-page p {
    margin-top: 5em;
    text-align: center;
    font-size: 1.5em;
  }
}

@media screen and (min-height: 916px) and (max-height: 1024px) {
  .over-shadow {
    min-height: 64em;
  }
}
