.about-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-page h2 {
  margin: 1em 0;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.about-page article {
  width: 80%;
  margin-bottom: 2em;
  font-size: 1.1em;
}
